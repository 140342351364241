// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-improvements-js": () => import("./../../../src/pages/home-improvements.js" /* webpackChunkName: "component---src-pages-home-improvements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-builds-js": () => import("./../../../src/pages/new-builds.js" /* webpackChunkName: "component---src-pages-new-builds-js" */),
  "component---src-pages-renovations-js": () => import("./../../../src/pages/renovations.js" /* webpackChunkName: "component---src-pages-renovations-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

